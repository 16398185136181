import { connect } from 'react-redux'
import { RegistrationValidationStep } from 'components/Auth'
import { signIn, setNetwork } from 'actions/auth'
import { bindActionCreators } from 'redux'

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ signIn, setNetwork }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegistrationValidationStep)

