import { SignOut } from 'components/Auth'
import { connect } from 'react-redux'
import { signOut } from 'actions/auth'
import { flashSuccess } from 'actions/flash'
import { bindActionCreators } from 'redux'

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ signOut, flashSuccess }, dispatch)
}

export default connect(
  null,
  mapDispatchToProps
)(SignOut)
