import 'react-app-polyfill/ie11'

import 'core-js/es/map'
import 'core-js/es/set'
import 'raf/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from './configureStore'
import Root from './Root'
import IframeRouter from './IframeRouter'
import Announcer from 'containers/Announcer'
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'
import DevRouter from './DevRouter'
import PropTypes from 'prop-types'
import { LocaleProvider } from 'antd'
import frFR from 'antd/lib/locale-provider/fr_FR'
import 'antd/dist/antd.css'
import './index.less'
import './index_iframe.less'
import { signOut } from './actions/auth'
import { flashWarning } from './actions/flash'
import localization from './utils/localization'
import { BrowserRouter } from 'react-router-dom'


function getJsonFromUrl(url) {
  var query = url.substr(1);
  var result = {};
  query.split("&").forEach(function(part) {
    var item = part.split("=");
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
}

let BRAND_TOKEN = "UNDEFINED_BRAND_ID"
if (process.env.BUILD_MODE === 'iframe') {
  BRAND_TOKEN = (window.BRAND_TOKEN) ? window.BRAND_TOKEN : BRAND_TOKEN
  window.ECOV_IFRAME = true
  let url = new URL(window.location.href);
  window.ECOV_IFRAME_PARAMS = (url.search ? url.search : '')
  window.ECOV_IFRAME_PARAM_ARRAY = getJsonFromUrl(url.search)
  window.ECOV_AFTER_SIGNIN_URL = 'profile'
  window.ECOV_AFTER_SIGNOUT_URL = 'profile'
}
else {
  BRAND_TOKEN = document.querySelector('[data-brand]') ? document.querySelector('[data-brand]').attributes['data-brand'].value : BRAND_TOKEN
  window.ECOV_IFRAME = false
  window.ECOV_IFRAME_PARAMS = ''
  window.ECOV_IFRAME_PARAM_ARRAY = {}
  window.ECOV_AFTER_SIGNOUT_URL = ''
}

function initStores (BRAND_TOKEN) {
  //const { store, persistor } = configureStore({ brand: { brand_token: BRAND_TOKEN } })
  const { store, persistor } = configureStore()
  const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_BASE}/graphql`,
    request: async operation => {
      const token = store.getState().auth.token

      operation.setContext({
        headers: {
          authorization: `Bearer ${token}`,
          'X-Brand': BRAND_TOKEN
        }
      })
    },
    onError: ({ networkError }) => {
      if (networkError && networkError.statusCode === 401) {
        store.dispatch(signOut())
        store.dispatch(
          flashWarning({
            message: localization.auth.sessionExpired.title,
            description: localization.auth.sessionExpired.description
          })
        )
        setTimeout(() => {
          if (window.ECOV_IFRAME) {
            window.location = 'sign-in' + window.ECOV_IFRAME_PARAMS
          }
          else {
            window.location = process.env.REACT_APP_SIGN_IN_PATH
          }
        }, 0)
      }
    }
  })
  return { store, persistor, client }
}

const { store, persistor, client } = initStores(BRAND_TOKEN)

const newDiv = document.createElement('div')
document.body.appendChild(newDiv)

const Wrapper = ({ children }) => {
  return (
    <BrowserRouter>
      <LocaleProvider locale={frFR}>
        <ApolloProvider client={client}>
          <Provider store={store} >
            <PersistGate loading={null} persistor={persistor}>
              {children}
            </PersistGate>
          </Provider>
        </ApolloProvider>
      </LocaleProvider>
    </BrowserRouter>
  )
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Announcer />
    </PersistGate>
  </Provider>,
  newDiv,
  // Component Announcer must be rendered before any other component
  // Hence this callback
  () => {
    if (process.env.NODE_ENV === 'development') {
      if (process.env.BUILD_MODE === 'iframe') {
        ReactDOM.render(
          <Wrapper>
            <IframeRouter />
          </Wrapper>,
          document.getElementById('root')
        )
      }
      else  {
        ReactDOM.render(
          <Wrapper>
            <DevRouter />
          </Wrapper>,
          document.getElementById('root')
        )
      }
    } else if (process.env.BUILD_MODE === 'iframe') {
        ReactDOM.render(
          <Wrapper>
            <IframeRouter />
          </Wrapper>,
          document.getElementById('root')
        )
    } else {
      [...document.querySelectorAll('[data-component]')].forEach(container => {
        const component = container.attributes['data-component'].value
        
        ReactDOM.render(
          <Wrapper>
            <Root component={component} />
          </Wrapper>,
          container
        )
      })
    }
  }
)

Wrapper.propTypes = {
  children: PropTypes.element.isRequired
}
