import React, { Component } from 'react';
import { Form, Button, Row, Col } from 'antd';
import localization from 'utils/localization';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Mutation, compose } from 'react-apollo';
import REQUEST_PASSWORD_RESET from 'api/mutation/requestPasswordReset.gql';
import { PhoneInputFormItem } from './phone-input/PhoneInputFormItem';

class RequestPasswordReset extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((error, value) => {
      if (!error) {
        this.props
          .requestPasswordReset({
            variables: {
              input: { ...value, phone: `+${value.phone}` },
            },
          })
          .then((resp) => {
            if (resp.data.requestPasswordReset.errors.length) {
              return Promise.reject(resp.data.requestPasswordReset.errors);
            }
            this.setState({ error: false });
            this.props.history.push(
              `/password-reset-verification/${value.phone}`
            );
          })
          .catch((errors) => {
            this.props.form.setFields({
              phone: {
                value: value.phone,
                errors,
              },
            });
          });
      }
    });
  };
  render() {
    const { form, loading } = this.props;

    return (
      <div className="form_wrapper">
        <Row type="flex" justify="center" style={{ marginBottom: '20px' }}>
          <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
            <h1 className="h1-style-inverse ">
              {localization.auth.forgotPasswordScreen.title}
            </h1>
            <p className="p-style-inverse ">
              {localization.auth.forgotPasswordScreen.description}
            </p>
          </Col>
        </Row>
        <form onSubmit={this.handleSubmit}>
          <Row type="flex" justify="center">
            <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
              <PhoneInputFormItem form={form} />
            </Col>
          </Row>
          <div>
            <Row type="flex" justify="center" style={{ marginBottom: '20px' }}>
              <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
                <Button
                  type="primary"
                  className="primary-button"
                  htmlType="submit"
                  loading={loading}
                >
                  {localization.submit}
                </Button>
              </Col>
            </Row>
          </div>
          <div>
            <Row type="flex" justify="center">
              <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
                <Link to="/sign-in" className="link-style-inverse">
                  {localization.auth.signIn}
                </Link>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    );
  }
}

RequestPasswordReset.propTypes = {
  form: PropTypes.object.isRequired,
  requestPasswordReset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default compose(Form.create({ fieldNameProp: 'name' }))((props) => (
  <Mutation mutation={REQUEST_PASSWORD_RESET}>
    {(requestPasswordReset, { loading }) => (
      <RequestPasswordReset
        requestPasswordReset={requestPasswordReset}
        loading={loading}
        {...props}
      />
    )}
  </Mutation>
));
