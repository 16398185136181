import React from 'react'
import Auth from 'pages/Auth'
import SignOut from 'pages/SignOut'
import Profile from 'pages/Profile'
import PropTypes from 'prop-types'

class Root extends React.Component {
  render () {
    const { component } = this.props

    switch (component) {
      case 'sign-up':
        return <Auth />
      case 'sign-in':
        return <Auth isSigningIn />
      case 'sign-out':
        return <SignOut />
      case 'profile':
        return <Profile />
      default:
        return null
    }
  }
}

Root.propTypes = {
  component: PropTypes.string.isRequired
}

export default Root
