import React from 'react'
import {
  RequestPasswordReset,
  PasswordResetVerification
} from 'components/Auth'
import SignIn from 'containers/SignIn'
import AfterSignIn from 'containers/AfterSignIn'
import ConfirmationCode from 'containers/ConfirmationCode'
import RegistrationBySteps from 'containers/RegistrationBySteps'
import RecoverPassword from 'containers/RecoverPassword'
import Alert from 'antd/lib/alert'
import 'antd/lib/alert/style/css'
import PropTypes from 'prop-types'

import { MemoryRouter as Router, Route, Switch } from 'react-router'
import localization from 'utils/localization'

// export const renderAuthRoutes = ( isSigningIn = true ) => {

//   return [

//   ]
// }

export const AuthPage = ({ isSigningIn }) => (
  <Router initialEntries={[isSigningIn ? '/sign-in' : '/sign-up']}>
    <Switch>
      <Route component={SignIn} path='/sign-in' exact />,
      <Route component={RegistrationBySteps} path='/sign-up' exact />,
      <Route component={RequestPasswordReset} path='/forgot-password' exact />,
      <Route component={PasswordResetVerification} path='/password-reset-verification/:phone' exact />,
      <Route component={RecoverPassword} path='/recover-password/:phone/:verificationCode' exact />,
      <Route
        path='/confirmation-code/:phone'
        render={props => (
          <ConfirmationCode {...props}>
            {isSigningIn ? (
              <Alert
                message={localization.auth.notConfirmed}
                type='warning' />
            ) : null}
          </ConfirmationCode>
        )}
      />,
      <Route
        path='/after-sign-in'
        render={props => (
          <AfterSignIn {...props} />
        )}
      />
    </Switch>
  </Router>
)

AuthPage.propTypes = {
  isSigningIn: PropTypes.bool
}

export default AuthPage
