import React from 'react'
import RegistrationPhoneStep from './RegistrationPhoneStep'
import RegistrationSmsStep from './RegistrationSmsStep'
import RegistrationUserInfosStep from './RegistrationUserInfosStep'
import RegisterSelectNetwork from "./RegisterSelectNetwork";
import PropTypes from 'prop-types'

class RegistrationValidationStep extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      codeSent: false,
      codeValidated: false,
      phone: null,
      code: null
    }
  }

  render () {
    const { auth, setNetwork } = this.props;
    const { phone, codeSent, codeValidated } = this.state;

    if(!auth.network){
      return <RegisterSelectNetwork setNetwork={setNetwork} {...this.props} />;
    }
    else if (codeValidated) {
      return (
        <RegistrationUserInfosStep
          phone={phone}
          network={auth.network}
          {...this.props}
        />
      );
    } else if (codeSent) {
      return (
        <RegistrationSmsStep phone={phone} {...this.props} codeValidated={(code) => { this.setState({ code: code, codeValidated: true }) }} />
      )
    } else {
      return (
        <RegistrationPhoneStep {...this.props} phoneValidated={(phone) => { this.setState({ phone: phone, codeSent: true }) }} />
      )
    }
  }
}

RegistrationValidationStep.propTypes = {
  signIn: PropTypes.func.isRequired,
  setNetwork: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

export default RegistrationValidationStep
