import React from 'react'
import { Spin, List, Divider, Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { Query } from 'react-apollo'
import ME from 'api/query/me.gql'
import localization from 'utils/localization'

class Profile extends React.Component {
  renderBankAccounts (user) {
    if (user.bankAccounts === null || user.bankAccounts.length === 0) {
      return null
    }
    return (
      <React.Fragment>
        <List
          dataSource={user.bankAccounts}
          renderItem={account => <List.Item>{account.iban}</List.Item>}
        />
      </React.Fragment>
    )
  }

  render () {
    return (
      <Query query={ME} fetchPolicy='network-only'>
        {({ loading, data, error }) => {
          if (error) return null
          if (loading) {
            return (
              <div className='form_wrapper' style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            )
          }

          const { me } = data
          return (
            <React.Fragment>
              <Row>
                <Col xs={{ span: 24 }} md={{ span: 4 }}>
                  <Row>
                    <Col>
                      <div className='display-flex-center'>
                        <p className='profile-sticker'>
                          {(
                            me.firstName.charAt(0) + me.lastName.charAt(0)
                          ).toUpperCase()}
                        </p>
                      </div>
                    </Col>
                    <Col>
                      <div className='display-flex-center margin-10px'>
                        <Link 
                          className='ant-btn ant-btn-primary primary-button'
                          to='/profile/edit'
                        >
                          {localization.edit}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col xs={{ span: 24 }} md={{ span: 20 }}>
                  <Row>
                    <Col span={18}>
                      <h2 className='profile-title-style'>
                        {localization.profile.personnalInfos}
                      </h2>
                    </Col>

                  </Row>
                  <Row>
                    <Col>
                      <Divider />
                    </Col>
                  </Row>
                  <Row>
                    <Col span={11}>
                      <Row>
                        <Col>
                          <p className='profile-label'>
                            {localization.firstName}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className='profile-label'>
                            {localization.lastName}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className='profile-label'>
                            {localization.dayOfBitrh}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className='profile-label'>{localization.email}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className='profile-label'>{localization.phone}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p className='profile-label'>{localization.shareCode}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={13}>
                      <Row>
                        <Col>
                          <p>{me.firstName}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>{me.lastName}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>{me.birthDate}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>{me.email}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>{me.phone}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>{me.shareCode}</p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row style={{ marginTop: 30 }}>
                <Col>
                  <h2 className='profile-title-style'>
                    {localization.profile.bankAccount}
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Divider />
                </Col>
              </Row>
              {this.renderBankAccounts(me)}
              <Row type='flex' justify='center' style={{ marginTop: 30 }}>
                <Col>
                  <Link
                    className='ant-btn ant-btn-primary primary-button'
                    to='/bank-details'
                  >
                    {localization.profile.addIban}
                  </Link>
                </Col>
              </Row>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
}

export default Profile
