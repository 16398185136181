import React from 'react'
import { Spin, Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { Query } from 'react-apollo'
import ME from 'api/query/me.gql'
import localization from 'utils/localization'

class MoneyPot extends React.Component {
  getAmount (wallets) {
    if (wallets === null || wallets.length === 0) {
      return '0 €'
    } else {
      return wallets[0].formatedAmount
    }
  }

  wiringAvailable (wallets) {
    return wallets && wallets.length > 0 && wallets[0].amount > 0
  }

  render () {
    return (
      <Query query={ME} fetchPolicy='network-only'>
        {({ loading, data, error }) => {
          if (error) return null
          if (loading) {
            return (
              <div className='form_wrapper' style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            )
          }
          const { me } = data
          return (
            <React.Fragment>
              <Row type='flex' justify='center'>
                <Col>
                  <h1 className='blue-text blue-text-title'>{localization.moneyPot.title}</h1>
                </Col>
              </Row>
              <Row type='flex' justify='center'>
                <Col>
                  <h2 className='money-pot-amount'>
                    {this.getAmount(me.wallets)}
                  </h2>
                </Col>
              </Row>
              <Row type='flex' justify='center'>
                <Col>
                  <p className='money-pot-label'>
                    {localization.moneyPot.available}
                  </p>
                </Col>
              </Row>
              {/* <Row type='flex' justify='center' style={{ marginTop: 15 }}>
                <Col>
                  <a href='../soutenir-le-projet/' accessibilityRole='link' target='_blank' rel='noopener noreferrer'>Soutenir financièrement le projet en redonnant le montant de ma cagnotte</a>
                </Col>
              </Row> */}
              {this.wiringAvailable(me.wallets) && (
                <Row type='flex' justify='center' style={{ marginTop: 15 }}>
                  <Col>
                    <Link className='ant-btn ant-btn-default primary-large-button' to='/wire-money'>{localization.moneyPot.transfertMoney}</Link>
                  </Col>
                </Row>
              )}
              <Row type='flex' justify='center' style={{ marginTop: 15 }}>
                <Col>
                  <Link className='ant-btn ant-btn-primary primary-large-button' to='/credit-money'> Ajouter de l'argent </Link>
                </Col>
              </Row>
            </React.Fragment>
          )
        }}
      </Query>
    )
  }
}

export default MoneyPot
