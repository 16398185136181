import { connect } from 'react-redux'
import { RecoverPassword } from 'components/Auth'
import { signIn } from 'actions/auth'
import { bindActionCreators } from 'redux'

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ signIn }, dispatch)
}

export default connect(
  null,
  mapDispatchToProps
)(RecoverPassword)
