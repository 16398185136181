import { Component } from 'react'
import PropTypes from 'prop-types'
import localization from 'utils/localization'

class SignOut extends Component {
  componentDidMount () {
    this.props.signOut()
    this.props.flashSuccess({
      message: localization.auth.signedOutMessage.title,
      description: localization.auth.signedOutMessage.description
    })
    setTimeout(() => { window.location = '/' + window.ECOV_AFTER_SIGNOUT_URL + window.ECOV_IFRAME_PARAMS }, 0)
  }
  render () {
    return null
  }
}

SignOut.propTypes = {
  signOut: PropTypes.func.isRequired,
  flashSuccess: PropTypes.func.isRequired
}

export default SignOut
