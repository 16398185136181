import React, { Component } from 'react';
import { Mutation, compose } from 'react-apollo';
import { Form, Input, Button } from 'antd';
import PropTypes from 'prop-types';
import VERIFY_PASSWORD_RESET from 'api/mutation/verifyPasswordReset.gql';
import localization from 'utils/localization';

class PasswordResetVerification extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((error, value) => {
      if (!error) {
        const { phone } = this.props.match.params;

        this.props
          .verifyPasswordReset({
            variables: { input: { ...value, phone: `+${phone}` } },
          })
          .then((resp) => {
            if (resp.data.verifyPasswordReset.errors.length) {
              return Promise.reject(resp.data.verifyPasswordReset.errors);
            }
            this.setState({ error: false });
            this.props.history.push(
              `/recover-password/${phone}/${value.verificationCode}`
            );
          })
          .catch((errors) => {
            this.props.form.setFields({
              verificationCode: {
                value: value.verificationCode,
                errors,
              },
            });
          });
      }
    });
  };

  render() {
    const {
      form: { getFieldDecorator },
      loading,
    } = this.props;

    return (
      <div className="form_wrapper">
        <form onSubmit={this.handleSubmit}>
          <Form.Item
            label={localization.auth.passwordReset.verificationInputLabel}
          >
            {getFieldDecorator('verificationCode', {
              rules: [
                { required: true, message: localization.form.requiredField },
              ],
            })(
              <Input
                placeholder={localization.auth.passwordReset.verificationCode}
              />
            )}
          </Form.Item>
          <div className="input_field">
            <Button type="primary" htmlType="submit" loading={loading} block>
              {localization.submit}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

PasswordResetVerification.propTypes = {
  form: PropTypes.object.isRequired,
  verifyPasswordReset: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default compose(Form.create({ fieldNameProp: 'name' }))((props) => (
  <Mutation mutation={VERIFY_PASSWORD_RESET}>
    {(verifyPasswordReset, { loading }) => (
      <PasswordResetVerification
        verifyPasswordReset={verifyPasswordReset}
        loading={loading}
        {...props}
      />
    )}
  </Mutation>
));
