import React from 'react'
import { Mutation, compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, Row, Col, Tooltip } from 'antd'
import PropTypes from 'prop-types'
import PasswordInput from 'components/Common/PasswordInput'
import localization from 'utils/localization'
import validationMessage from 'utils/validationMessage'
import REGISTER_USER_COMPLETION from 'api/mutation/registerUserCompletion.gql'

class RegistrationUserInfosStep extends React.Component {
  state = {
    passwordTrigger: 'onBlur'
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.form.validateFields((error, value) => {
      if (!error) {
        value.phone = this.props.phone;

        value.networkId = this.props.network;
        this.props.registerUserCompletionStep({
          variables: {
            input: value
          }
        })
          .then(resp => {
            if (resp.data.registerUserCompletionStep.errors.length) {
              return Promise.reject(resp.data.registerUserCompletionStep.errors)
            }
            if (typeof window.fbq !== 'undefined') {
              window.fbq('track', 'CompleteRegistration')
            }
            this.props.signIn(resp.data.registerUserCompletionStep)
            if (! window.ECOV_AFTER_SIGNUP_URL) {
              this.props.history.push('/after-sign-in' + window.ECOV_IFRAME_PARAMS)
            }
            else {
              window.location = window.ECOV_AFTER_SIGNUP_URL + window.ECOV_IFRAME_PARAMS
            }
          })
          .catch(err => {
            const fields = err.reduce((fields, e) => {
              let errors = ((fields[e.attribute] && fields[e.attribute].errors) || [])
              errors.push(validationMessage(e.message))
              fields[e.attribute] = { value: value[e.attribute], errors }
              return fields
            }, {})

            this.props.form.setFields(fields)
          })
      }
    })
  }

  render () {
    const { form: { getFieldDecorator }, loading } = this.props
    return (
      <div className='form_wrapper'>
        <Row type='flex' justify='center' style={{ marginBottom: '20px' }}>
          <Col type='flex' justify='center' style={{ textAlign: 'center' }}>
            <h1 className='h1-style-inverse '>{localization.auth.signUp}</h1>
          </Col>
        </Row>
        <form onSubmit={this.handleSubmit}>
          <Row type='flex' justify='center'>
            <Col type='flex' justify='center' style={{ textAlign: 'center' }}>
              <Form.Item hasFeedback>
                {getFieldDecorator('firstName', { initialValue: '', rules: [{ required: true, message: localization.form.requiredField }] })(
                  <Input type='text' placeholder={localization.firstName} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' justify='center'>
            <Col type='flex' justify='center' style={{ textAlign: 'center' }}>
              <Form.Item hasFeedback>
                {getFieldDecorator('lastName', { initialValue: '', rules: [{ required: true, message: localization.form.requiredField }] })(
                  <Input type='text' placeholder={localization.lastName} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' justify='center'>
            <Col type='flex' justify='center' style={{ textAlign: 'center' }}>
              <Form.Item hasFeedback>
                {getFieldDecorator('email', { initialValue: '', rules: [{ pattern: /[^@\s]+@[^@\s]+[.]+[^@\s]+/, message: localization.form.invalidEmail }] })(
                  <Input type='text' placeholder={localization.email} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' justify='center'>
            <Col type='flex' justify='center' style={{ textAlign: 'center' }}>
              <Form.Item hasFeedback label={localization.dayOfBirth}>
                {window.ECOV_IFRAME &&
                <Tooltip
                  trigger={['focus']}
                  title={<p>Pour vous inscrire, vous devez être âgé d’au moins 16 ans. Les mineurs doivent fournir une <a href="https://ecov-marqueblanche-resources.s3.eu-central-1.amazonaws.com/documents/Autorisation_parentale.pdf" target="_blank" rel="noopener noreferrer"><font color='white'><b>autorisation parentale</b></font></a>. Les autres utilisateurs ne verront pas votre date de naissance.</p>}
                  placement='right'
                  overlayClassName='tooltip-birthDate'
                >
                  {getFieldDecorator('birthDate', { initialValue: '', rules: [{ required: true, message: localization.form.requiredField }] })(
                    <Input type='date' />
                  )}
                </Tooltip>
                }
                {!window.ECOV_IFRAME &&
                  <Tooltip
                  trigger={['focus']}
                  title={<p>Pour vous inscrire, vous devez être âgé d’au moins 16 ans. Les mineurs doivent fournir une <a href={ window.ECOV_PARENTAL_PERMISSION_URL}><font color='white'><b>autorisation parentale</b></font></a>. Les autres utilisateurs ne verront pas votre date de naissance.</p>}
                  placement='right'
                  overlayClassName='tooltip-birthDate'
                >
                  {getFieldDecorator('birthDate', { initialValue: '', rules: [{ required: true, message: localization.form.requiredField }] })(
                    <Input type='date' />
                  )}
                </Tooltip>
                }
              </Form.Item>
            </Col>
          </Row>
          <Row type='flex' justify='center'>
            <Col type='flex' justify='center' style={{ textAlign: 'center' }}>
              <Form.Item hasFeedback>
                {getFieldDecorator('password', {
                  initialValue: '',
                  rules: [{ required: true, message: localization.form.passwordField, pattern: /^.{6,}$/ }],
                  validateTrigger: this.state.passwordTrigger
                })(
                  <PasswordInput placeholder={localization.passwordPlaceholder} onBlur={this.handleChange} />
                )}
              </Form.Item>
            </Col>
          </Row>
          <div>
            <Row type='flex' justify='center' style={{ marginBottom: '20px' }}>
              <Col type='flex' justify='center' style={{ textAlign: 'center' }}>
                <Button htmlType='submit' loading={loading} type='primary'>
                  {localization.auth.signUpCreate}
                </Button>
              </Col>
            </Row>

          </div>
        </form>
      </div>
    )
  }
}

RegistrationUserInfosStep.propTypes = {
  form: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
  registerUserCompletionStep: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  // history: PropTypes.object.isRequired,
  phone: PropTypes.string.isRequired,
  network: PropTypes.number.isRequired,
};

export default compose(
  withRouter,
  Form.create()
)(props => (
  <Mutation mutation={REGISTER_USER_COMPLETION}>
    {(registerUserCompletionStep, { loading }) => (
      <RegistrationUserInfosStep registerUserCompletionStep={registerUserCompletionStep} loading={loading} {...props} />
    )}
  </Mutation>
))
