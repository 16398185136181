import React from 'react'
import notification from 'antd/lib/notification'
import PropTypes from 'prop-types'

// This is the component to render after a user has signed in
// either while logging in or after he confirmed his phone number.
// Its role is to redirect (render) to a certain component after a sign-in
// (if afterSignInPath is defined) or to the root path
class AfterSignIn extends React.Component {
  render () {
    const { afterSignInPath } = this.props
    
    if (afterSignInPath) {
      // return <Redirect to={afterSignInPath} />
      window.location = afterSignInPath  + window.ECOV_IFRAME_PARAMS
    }
    if (window.ECOV_AFTER_SIGNIN_URL) {
      window.location = window.ECOV_AFTER_SIGNIN_URL  + window.ECOV_IFRAME_PARAMS
    }
    return null
  }

  componentDidMount () {
    if (this.props.afterSignInPath || window.ECOV_AFTER_SIGNIN_URL) {
      notification['success']({
        message: 'Connexion',
        description: 'Bienvenue, vous avez été authentifié avec succès.'
      })
    } else {
      this.props.flashSuccess({ message: 'Connexion', description: 'Bienvenue' })
      window.location = process.env.REACT_APP_HOME_PATH + window.ECOV_IFRAME_PARAMS
    }
  }
}

AfterSignIn.propTypes = {
  afterSignInPath: PropTypes.string,
  flashSuccess: PropTypes.func.isRequired
}

export default AfterSignIn
