import React from 'react';
import { Input } from 'antd';
import PropTypes from 'prop-types';
import ButtonIcon from './ButtonIcon';

class PasswordInput extends React.Component {
  state = {
    type: 'password',
    visible: false,
  };

  toggle = (e) => {
    e.preventDefault();
    const type = this.state.type;

    console.log('Toggle');
    this.setState({ type: type === 'password' ? 'text' : 'password' });
  };

  handleChange = (e) => {
    const visible = !!e.target.value;
    const type = visible ? this.state.type : 'password';
    this.setState({ visible, type });
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  };

  render() {
    const { type, visible } = this.state;

    return (
      <Input
        type={type}
        {...this.props}
        onChange={this.handleChange}
        suffix={
          <ButtonIcon
            type="eye"
            onClick={this.toggle}
            style={{ visibility: visible ? 'visible' : 'hidden' }}
          />
        }
      />
    );
  }
}

PasswordInput.propTypes = {
  onChange: PropTypes.func,
};

export default PasswordInput;
