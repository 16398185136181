import React from 'react';
import { Form, Button, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { compose, Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import localization from 'utils/localization';
import REGISTER_USER_PHONE from 'api/mutation/registerUserPhone.gql';
import { PhoneInputFormItem } from './phone-input/PhoneInputFormItem';

class RegistrationPhoneStep extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((error, value) => {
      if (!error) {
        const phone = `+${value.phone}`;
        this.props
          .registerUserPhoneStep({
            variables: { input: { phone } },
          })
          .then((resp) => {
            if (resp.data.registerUserPhoneStep.errors.length) {
              return Promise.reject(resp.data.registerUserPhoneStep.errors);
            }
            this.setState({
              error: false,
            });
            this.props.phoneValidated(value.phone);
          })
          .catch((errors) => {
            this.props.form.setFields({
              phone: {
                value: value.phone,
                errors,
              },
            });
          });
      }
    });
  };

  render() {
    const { form, loading } = this.props;
    return (
      <div className="form_wrapper">
        <Row type="flex" justify="center" style={{ marginBottom: '20px' }}>
          <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
            <h1 className="h1-style-inverse ">{localization.auth.signUp}</h1>
          </Col>
        </Row>
        <form onSubmit={this.handleSubmit}>
          <Row type="flex" justify="center">
            <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
              <PhoneInputFormItem form={form} />
            </Col>
          </Row>
          <div>
            <Row type="flex" justify="center">
              <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
                <Button
                  className="primary-button"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {localization.submit}
                </Button>
              </Col>
            </Row>
          </div>
          <div style={{ height: '20px' }}></div>
          <div>
            <Row type="flex" justify="center">
              <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
                <Link to="sign-in" className="link-style-inverse">
                  {localization.auth.alreadyRegistered}
                </Link>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    );
  }
}

RegistrationPhoneStep.propTypes = {
  registerUserPhoneStep: PropTypes.func.isRequired,
  phoneValidated: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default compose(Form.create())((props) => (
  <Mutation mutation={REGISTER_USER_PHONE}>
    {(registerUserPhoneStep, { loading }) => (
      <RegistrationPhoneStep
        registerUserPhoneStep={registerUserPhoneStep}
        loading={loading}
        {...props}
      />
    )}
  </Mutation>
));
