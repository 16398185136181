import { actionTypes } from 'actions/flash'

const inititalState = {
  message: null,
  description: null,
  type: null
}

export default (state = inititalState, action) => {
  switch (action.type) {
    case actionTypes.SET:
      const { message, description, type } = action.payload
      return { ...state, message, description, type }

    case actionTypes.CLEAR:
      return { ...state, message: null, description: null, type: null }

    default:
      return state
  }
}
