import { connect } from 'react-redux'
import { AfterSignIn } from 'components/Auth'
import { signIn } from 'actions/auth'
import { flashSuccess } from 'actions/flash'
import { bindActionCreators } from 'redux'

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ signIn, flashSuccess }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AfterSignIn)
