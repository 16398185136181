export const actionTypes = {
  SET_NETWORK: 'SET_NETWORK',
  SIGN_IN: 'SIGN_IN',
  SIGN_OUT: 'SIGN_OUT'
}

export const setNetwork = payload => {
  return {
    type: actionTypes.SET_NETWORK,
    payload,
  };
}

export const signIn = payload => {
  return {
    type: actionTypes.SIGN_IN,
    payload
  }
}

export const signOut = () => {
  return {
    type: actionTypes.SIGN_OUT
  }
}
