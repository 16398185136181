import React from 'react'
import { Mutation } from 'react-apollo'
import resendPhoneConfirmationMutation from 'api/mutation/resendPhoneConfirmation.gql'
import notification from 'antd/lib/notification'
import Spin from 'antd/lib/spin'
import 'antd/lib/spin/style/css'
import PropTypes from 'prop-types'
import localization from 'utils/localization'

class ResendPhoneConfirmation extends React.Component {
  resendPhoneConfirmation = e => {
    this.props.resendPhoneConfirmation({ variables: { input: { phone: this.props.phone } } })
      .then(() => {
        notification['success']({
          message: localization.auth.resendConfirmationCode.success.title,
          description: localization.auth.resendConfirmationCode.success.description,
          duration: 5
        })
      })
      .catch(() => {
        notification['error']({
          message: localization.auth.resendConfirmationCode.failure.title,
          description: localization.auth.resendConfirmationCode.failure.description,
          duration: 5
        })
      })
  }

  render () {
    return this.props.loading ? <Spin size='small' />
      : (
        <a href='#resend-confirmation' onClick={this.resendPhoneConfirmation}>{localization.auth.resendConfirmationCode.request}</a>
      )
  }
}

ResendPhoneConfirmation.propTypes = {
  resendPhoneConfirmation: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  phone: PropTypes.string.isRequired
}

export default (props) => (
  <Mutation mutation={resendPhoneConfirmationMutation}>
    {(resendPhoneConfirmation, { loading }) => (
      <ResendPhoneConfirmation resendPhoneConfirmation={resendPhoneConfirmation} loading={loading} {...props} />
    )}
  </Mutation>
)
