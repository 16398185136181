import React from 'react'
import { Form, Row, Col, Button, Input, InputNumber, Spin } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { Mutation, compose, Query } from 'react-apollo'
import PropTypes from 'prop-types'
import mangoPay from 'mangopay-cardregistration-js-kit'
import PAY_IN from 'api/mutation/payIn.gql'
import INIT_CARD_REGISTRATION from 'api/query/initCardRegistration.gql'
import localization from 'utils/localization'
import attention from '../../../images/attention.png'

mangoPay.cardRegistration.baseURL = process.env.REACT_APP_MANGOPAY_BASE_URL
mangoPay.cardRegistration.clientId = process.env.REACT_APP_MANGOPAY_CLIENT_ID

class CreditMoney extends React.Component {
  state = {
    payInStatus: 'new'
  };

  handleSubmit = (e, preRegistrationData) => {
    e.preventDefault()
    this.props.form.validateFields((error, { amount, cardNumber, cardExpirationDate, cardCvx }) => {
      if (!error) {
        mangoPay.cardRegistration.init({
          Id: preRegistrationData.id,
          cardRegistrationURL: preRegistrationData.registrationUrl,
          preregistrationData: preRegistrationData.preRegistrationData,
          accessKey: preRegistrationData.accessKey
        })
        mangoPay.cardRegistration.registerCard(
          { cardNumber, cardExpirationDate: cardExpirationDate.replace('/', ''), cardCvx, cardType: 'CB_VISA_MASTERCARD' },
          ({ CardId: cardId }) => {
            this.props.payIn({
              variables: {
                input: {
                  amount: amount * 100,
                  cardId
                }
              }
            })
              .then(resp => {
                if (resp.data.payIn.errors == null) {
                  return Promise.resolve()
                } else {
                  return Promise.reject(resp.data.payIn.errors)
                }
              })
              .then(() => this.setState({ payInStatus: 'success' }))
              .catch(errors => {
                console.warn(errors)
                this.setState({ payInStatus: 'failure' })
              })
          },
          console.error
        )
      }
    })
  };

  displayForm () {
    const {
      form: { getFieldDecorator }
    } = this.props
    return (
      <Query query={INIT_CARD_REGISTRATION}>
        {({ loading, data, error }) => {
          if (loading) {
            return (
              <div className='form_wrapper' style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            )
          }

          return (
            <div className='form_wrapper'>
              <form onSubmit={e => this.handleSubmit(e, data.initCardRegistration)}>
                <Row type='flex' justify='center'>
                  <Col>
                    <p className='blue-text-title'>{localization.moneyPot.payInForm.amount}</p>
                  </Col>
                </Row>
                <Row type='flex' justify='center'>
                  <Col>
                    <Form.Item>
                      {getFieldDecorator('amount', {
                        initialValue: '',
                        rules: [
                          {
                            required: true,
                            message: localization.form.requiredField
                          }
                        ]
                      })(
                        <InputNumber
                          size='large'
                          formatter={value => `${value} €`}
                          parser={value => value.replace('€', '')}
                        />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row type='flex' justify='center' style={{ textAlign: 'center' }}>
                  <Col>
                    <p>{localization.moneyPot.payInForm.cardNumberLabel}</p>
                  </Col>
                </Row>
                <Row type='flex' justify='center'>
                  <Col type='flex' justify='center' style={{ textAlign: 'center' }}>
                    <Form.Item>
                      {getFieldDecorator('cardNumber', {
                        initialValue: '',
                        rules: [
                          {
                            required: true,
                            message: localization.form.requiredField
                          }
                        ]
                      })(
                        <Input type='text' placeholder={localization.moneyPot.payInForm.cardNumber} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row
                  type='flex'
                  justify='center'
                  gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
                  style={{ marginBottom: '20px' }}
                >
                  <Col type='flex' className='display-flex-end' span={12}>
                    {getFieldDecorator('cardExpirationDate', {
                      initialValue: '',
                      rules: [
                        { required: true, message: localization.form.requiredField }
                      ]
                    })(
                      <Input
                        type='text'
                        placeholder={'mm/yy'}
                        style={{ width: '88px', minWidth: '88px' }}
                      />
                    )}
                  </Col>
                  <Col span={12}>
                    {getFieldDecorator('cardCvx', {
                      initialValue: '',
                      rules: [
                        { required: true, message: localization.form.requiredField }
                      ]
                    })(<Input type='text' placeholder={'CSV'}
                      style={{ width: '88px', minWidth: '88px' }} />)}
                  </Col>
                </Row>
                <Row type='flex' justify='center' style={{ marginTop: 15 }}>
                  <Col>
                    <Button
                      htmlType='submit'
                      type='primary'
                      className='primary-large-button'
                    >
                      Créditer
                    </Button>
                  </Col>
                </Row>
                <Row type='flex' justify='center' style={{ marginTop: 15 }}>
                  <Col>
                    <Button type='default' className='primary-large-button'>
                      <Link to='/'>{localization.cancel}</Link>
                    </Button>
                  </Col>
                </Row>
              </form>
            </div>
          )
        }}
      </Query>
    )
  }

  renderPayInSuccess () {
    return (
      <React.Fragment>
        <Row type='flex' justify='center' style={{ textAlign: 'center' }}>
          <Col>
            <h1 className='blue-text blue-text-title'>{localization.moneyPot.title}</h1>
          </Col>
        </Row>
        <Row type='flex' justify='center'>
          <Col>
            <p>{localization.moneyPot.creditMoneySuccess}</p>
          </Col>
        </Row>
        <Row type='flex' justify='center'>
          <Col>
            <Button type='default' className='primary-large-button'>
              <Link to='/money-pot'>{localization.back}</Link>
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  renderPayInFailed () {
    return (
      <React.Fragment>
        <Row type='flex' justify='center' style={{ textAlign: 'center' }}>
          <Col>
            <h1 className='blue-text'>{localization.moneyPot.title}</h1>
          </Col>
        </Row>
        <Row type='flex' justify='center'>
          <Col>
            <img
              alt=''
              style={{ height: '75px', marginBottom: 30, marginTop: 30 }}
              src={attention}
            />
          </Col>
        </Row>
        <Row type='flex' justify='center'>
          <Col>
            <p>{localization.moneyPot.creditMoneyFailure}</p>
          </Col>
        </Row>
        <Row type='flex' justify='center'>
          <Col>
            <Button type='default' className='primary-large-button'>
              <Link to='/money-pot'>{localization.back}</Link>
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  renderPayInForm () {
    return (
      <React.Fragment>
        <Row type='flex' justify='center' style={{ textAlign: 'center' }}>
          <Col>
            <h1 className='blue-text blue-text-title'>
              Créditez votre cagnotte avec votre carte bancaire en toute
              sécurité.
            </h1>
          </Col>
        </Row>
        {this.displayForm()}
      </React.Fragment>
    )
  }

  render () {
    switch (this.state.payInStatus) {
      case 'success':
        return this.renderPayInSuccess()
      case 'failure':
        return this.renderPayInFailed()

      default:
        return this.renderPayInForm()
    }
  }
}

CreditMoney.propTypes = {
  form: PropTypes.object.isRequired,
  payIn: PropTypes.func.isRequired
}

export default compose(
  withRouter,
  Form.create()
)(props => (
  <Mutation mutation={PAY_IN}>
    {(payIn, { data, loading }) => (
      <CreditMoney payIn={payIn} data={data} loading={loading} {...props} />
    )}
  </Mutation>
))
