import React from 'react'
import confirmPhoneNumberMutation from 'api/mutation/confirmPhoneNumber.gql'
import { Mutation, compose } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { Form, Button, message } from 'antd'
import ResendPhoneConfirmation from './ResendPhoneConfirmation'
import PropTypes from 'prop-types'
import localization from 'utils/localization'

class ConfirmationCode extends React.Component {
  handleSubmit = (e) => {
    e.preventDefault()

    this.props.form.validateFields((error, value) => {
      if (!error) {
        this.props.confirmPhoneNumber(
          { variables: { input: { ...value, phone: this.props.match.params.phone } } }
        ).then((resp) => {
          if (resp.data.confirmPhoneNumber.errors.length) {
            return Promise.reject(resp.data.confirmPhoneNumber.errors)
          }
          this.props.signIn(resp.data.confirmPhoneNumber)
          this.props.history.push('/after-sign-in' + window.ECOV_IFRAME_PARAMS)
        }).catch(errors => {
          errors.forEach(error => {
            message.error(error.message)
          })
        })
      }
    })
  }

  render () {
    const { form: { getFieldDecorator }, loading } = this.props

    return (
      <div className='form_wrapper'>
        {this.props.children}
        <h3 style={{ marginTop: this.props.children ? 20 : 0 }}>
          {localization.auth.SMSConfirmationCode}
        </h3>
        <br />
        <form onSubmit={this.handleSubmit}>
          <div className='input_field'>
            {getFieldDecorator('phoneConfirmationCode', { rules: [
              { required: true, message: localization.form.requiredField },
              { len: 4, message: localization.form.codeLength }
            ] })(
              <input type='text' placeholder={localization.auth.confirmationCode} />
            )}
          </div>
          <div className='input_field'>
            <Button type='primary' htmlType='submit' loading={loading} block>
              {localization.submit}
            </Button>
          </div>
          <ResendPhoneConfirmation phone={this.props.match.params.phone} />
        </form>
      </div>
    )
  }
}

ConfirmationCode.propTypes = {
  form: PropTypes.object.isRequired,
  confirmPhoneNumber: PropTypes.func.isRequired,
  children: PropTypes.node,
  match: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
}

export default compose(
  withRouter,
  Form.create()
)((props) => (
  <Mutation mutation={confirmPhoneNumberMutation}>
    {(confirmPhoneNumber, { data, loading }) => (
      <ConfirmationCode confirmPhoneNumber={confirmPhoneNumber} data={data} loading={loading} {...props} />
    )}
  </Mutation>
))
