import React from 'react'
import { Form, Spin, notification, Select, Row, Col, Button } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { Query, Mutation, compose } from 'react-apollo'
import ME from 'api/query/me.gql'
import PropTypes from 'prop-types'
import payoutMutation from 'api/mutation/payout.gql'
import localization from 'utils/localization'

const Option = Select.Option

class WireMoney extends React.Component {
  state = {
    wireMoneySuccess: false
  };

  handleSubmit = (e, refetch) => {
    e.preventDefault()
    this.props.form.validateFields((error, value) => {
      if (!error) {
        value.amount = 0
        this.props
          .payout({
            variables: { input: value }
          })
          .then(resp => {
            if (resp.data.payout.errors.length) {
              return Promise.reject(resp.data.addBankAccount.errors)
            }
            this.setState({ wireMoneySuccess: true })
          })
          .catch(err => {
            notification['error']({
              message: localization.moneyPot.transfertMoney,
              description: localization.moneyPot.wireMoneyError,
              placement: 'bottomRight'
            })
            console.log(err)
          })
      }
    })
  };

  getAmount (wallets) {
    if (wallets === null || wallets.length === 0) {
      return '0 €'
    } else {
      return wallets[0].formatedAmount
    }
  }

  renderBankAccountsSelector (me) {
    let options = me.bankAccounts.map(account => {
      return (
        <Option key={account.accountId} value={account.accountId}>
          {account.iban}
        </Option>
      )
    })
    return <Select>{options}</Select>
  }

  displayForm (me) {
    const {
      form: { getFieldDecorator }
    } = this.props
    return (
      <div className='form_wrapper'>
        <form onSubmit={e => this.handleSubmit(e)}>
          <Form.Item>
            {getFieldDecorator('accountId', {
              initialValue: me.bankAccounts[0].accountId,
              rules: [{ required: true }]
            })(this.renderBankAccountsSelector(me))}
          </Form.Item>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <Button htmlType='submit' type='primary' block>
                {localization.submit}
              </Button>
            </Col>

            <Col span={12}>
              <Button type='default' block>
                <Link to='/'>{localization.cancel}</Link>
              </Button>
            </Col>
          </Row>
        </form>
      </div>
    )
  }

  noAccount () {
    return (
      <Row type='flex' justify='center'>
        <Col>
          <p>{localization.moneyPot.addAnAccount}</p>
        </Col>
      </Row>
    )
  }

  wireSuccessView () {
    return (
      <React.Fragment>
        <Row type='flex' justify='center'>
          <Col>
            <h1 className='blue-text blue-text-title'>{localization.moneyPot.title}</h1>
          </Col>
        </Row>
        <Row type='flex' justify='center'>
          <Col>
            <p>{localization.moneyPot.wireMoneySuccess}</p>
          </Col>
        </Row>
        <Row type='flex' justify='center'>
          <Col>
            <Button type='default' className='primary-large-button'>
              <Link to='/money-pot'>{localization.back}</Link>
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    )
  }

  walletWiringView () {
    return (
      <Query query={ME}>
        {({ loading, data, error }) => {
          if (error) return null
          if (loading) {
            return (
              <div className='form_wrapper' style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            )
          }

          const { me } = data
          // let accountAvailable = me.bankAccounts.length > 0
          let accountAvailable = me.bankAccounts !== null && me.bankAccounts.length > 0
          return (
            <React.Fragment>
              <Row type='flex' justify='center'>
                <Col>
                  <h1 className='blue-text blue-text-title'>
                    {localization.moneyPot.transfertMoney}
                  </h1>
                </Col>
              </Row>
              <Row type='flex' justify='center'>
                <Col>
                  <h2 className='money-pot-amount'>
                    {this.getAmount(me.wallets)}
                  </h2>
                </Col>
              </Row>
              <Row type='flex' justify='center'>
                <Col>
                  <p>Sélectionnez le compte à créditer :</p>
                </Col>
              </Row>
              {accountAvailable ? this.displayForm(me) : this.noAccount()}
            </React.Fragment>
          )
        }}
      </Query>
    )
  }

  render () {
    return this.state.wireMoneySuccess
      ? this.wireSuccessView()
      : this.walletWiringView()
  }
}

WireMoney.propTypes = {
  form: PropTypes.object.isRequired,
  payout: PropTypes.func.isRequired
}

export default compose(
  withRouter,
  Form.create()
)(props => (
  <Mutation mutation={payoutMutation}>
    {(payout, { data, loading }) => (
      <WireMoney payout={payout} data={data} loading={loading} {...props} />
    )}
  </Mutation>
))
