import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Form } from 'antd';

import localization from '../../../utils/localization';
import { normalize } from './normalize';

const countries = window.COUNTRIES || ['fr'];
const onlyOneCountry = countries.length === 1;

export const PhoneInputFormItem = ({ form }) => {
  const { getFieldDecorator } = form;
  return (
    <Form.Item>
      {getFieldDecorator('phone', {
        normalize,
        rules: [
          {
            required: true,
            message: localization.form.requiredField,
          },
        ],
      })(
        <PhoneInput
          buttonStyle={{
            display: onlyOneCountry ? 'none' : undefined,
            marginTop: 3,
            marginBottom: 2,
          }}
          inputStyle={{
            paddingLeft: onlyOneCountry ? '8px' : undefined,
          }}
          country={countries[0]}
          onlyCountries={countries}
          placeholder={localization.phone}
          preserveOrder={['onlyCountries']}
        />
      )}
    </Form.Item>
  );
};
