import React from 'react'
import { Row, Col, Form, Input, Spin, Button, Divider, notification } from 'antd'
import { Query, Mutation, compose } from 'react-apollo'
import PropTypes from 'prop-types'
import ME from 'api/query/me.gql'
import localization from 'utils/localization'
import { Link, withRouter } from 'react-router-dom'
import ADD_BANK_ACCOUNT from 'api/mutation/addBankAccount.gql'

class BankDetails extends React.Component {
  handleSubmit = (e, refetch) => {
    e.preventDefault()

    this.props.form.validateFields((error, value) => {
      if (!error) {
        this.props.addBankAccount({
          variables: { input: value }
        })
          .then(resp => {
            if (resp.data.addBankAccount.errors.length) {
              return Promise.reject(resp.data.addBankAccount.errors)
            }
            notification['success']({
              message: localization.profile.update,
              description: localization.profile.updateSuccess,
              placement: 'bottomRight'
            })
            this.props.history.push('/' + window.ECOV_IFRAME_PARAMS)
            setTimeout(() => refetch(), 0)
          })
          .catch(err => {
            notification['error']({
              message: localization.profile.update,
              description: localization.profile.addBankAccountError,
              placement: 'bottomRight'
            })
            console.log(err)
          })
      }
    })
  }
  render () {
    const { form: { getFieldDecorator }, updating } = this.props

    return (
      <Query query={ME}>
        {({ loading, data: { me }, refetch }) => {
          if (loading) {
            return (
              <div className='form_wrapper' style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            )
          }
          return (
            <React.Fragment>
              <Row>
                <Col>
                  <h2 className='profile-title-style'>{localization.profile.bankAccount}</h2>
                </Col>
              </Row>
              <Row style={{ marginBottom: 15 }}>
                <Col>
                  <Divider />
                </Col>
              </Row>
              <form onSubmit={e => this.handleSubmit(e, refetch)}>
                <Form.Item hasFeedback>
                  {getFieldDecorator('iban', { initialValue: '', rules: [{ required: true, message: localization.form.requiredField }] })(
                    <Input className='ant-input-full-width' type='text' placeholder='iban' />
                  )}
                </Form.Item>
                <Form.Item hasFeedback>
                  {getFieldDecorator('addressLine1', { initialValue: '', rules: [{ required: true, message: localization.form.requiredField }] })(
                    <Input className='ant-input-full-width' type='text' placeholder='Adresse ligne 1' />
                  )}
                </Form.Item>
                <Form.Item hasFeedback>
                  {getFieldDecorator('addressLine2', { initialValue: '', rules: [{ required: false }] })(
                    <Input className='ant-input-full-width' type='text' placeholder='Adresse ligne 2' />
                  )}
                </Form.Item>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item hasFeedback>
                      {getFieldDecorator('city', { initialValue: me.city,
                        rules: [{ required: true, message: localization.form.requiredField }]
                      })(
                        <Input className='ant-input-full-width' type='text' placeholder={localization.city} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item hasFeedback>
                      {getFieldDecorator('postalCode', { initialValue: me.postalCode,
                        rules: [{ required: true, message: localization.form.requiredField }]
                      })(
                        <Input className='ant-input-full-width' type='text' placeholder={localization.postalCode} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12}>
                    <Button htmlType='submit' type='primary' loading={updating} block>
                      {localization.submit}
                    </Button>
                  </Col>

                  <Col span={12}>
                    <Button type='default' block>
                      <Link to='/' >Annuler</Link>
                    </Button>
                  </Col>
                </Row>
              </form>
            </React.Fragment>
          )
        }}
      </Query >
    )
  }
}

BankDetails.propTypes = {
  form: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  updating: PropTypes.bool.isRequired,
  addBankAccount: PropTypes.func.isRequired
}

export default compose(
  withRouter,
  Form.create({ fieldNameProp: 'name' })
)(props => (
  <Mutation mutation={ADD_BANK_ACCOUNT}>
    {(addBankAccount, { loading }) => (
      <BankDetails addBankAccount={addBankAccount} updating={loading} {...props} />
    )}
  </Mutation>
))
