import React from 'react'
import registerUserMutation from 'api/mutation/registerUser.gql'
import { Mutation, compose } from 'react-apollo'
import { withRouter, Link } from 'react-router-dom'
import { Form, Input, Button } from 'antd'
import PropTypes from 'prop-types'
import PasswordInput from 'components/Common/PasswordInput'
import localization from 'utils/localization'
import validationMessage from 'utils/validationMessage'

class RegistrationForm extends React.Component {
  state = {
    passwordTrigger: 'onBlur'
  }

  handleChange = (e) => {
    this.setState({ passwordTrigger: 'onChange' },
      () => {
        this.props.form.validateFields(['password'], { force: true })
      })
  }

  handleSubmit = (e) => {
    e.preventDefault()

    this.props.form.validateFields((error, value) => {
      if (!error) {
        this.props.registerUser({
          variables: {
            input: value
          }
        })
          .then(resp => {
            if (resp.data.registerUser.errors.length) {
              return Promise.reject(resp.data.registerUser.errors)
            }
            this.props.history.push(`/confirmation-code/${value.phone}`)
          })
          .catch(err => {
            const fields = err.reduce((fields, e) => {
              let errors = ((fields[e.attribute] && fields[e.attribute].errors) || [])
              errors.push(validationMessage(e.message))
              fields[e.attribute] = { value: value[e.attribute], errors }
              return fields
            }, {})

            this.props.form.setFields(fields)
          })
      }
    })
  }

  render () {
    const { form: { getFieldDecorator }, loading } = this.props

    return (
      <div className='form_wrapper'>
        <form onSubmit={this.handleSubmit}>
          <Form.Item hasFeedback>
            {getFieldDecorator('firstName', { initialValue: '',
              rules: [ { required: true, message: localization.form.requiredField } ]
            })(
              <Input type='text' placeholder={localization.firstName} />
            )}
          </Form.Item>
          <Form.Item hasFeedback>
            {getFieldDecorator('lastName', { initialValue: '',
              rules: [ { required: true, message: localization.form.requiredField } ]
            })(
              <Input type='text' placeholder={localization.lastName} />
            )}
          </Form.Item>
          <Form.Item hasFeedback>
            {getFieldDecorator('phone', { initialValue: '',
              rules: [ { required: true, message: localization.form.requiredField } ]
            })(
              <Input type='text' placeholder={localization.phone} />
            )}
          </Form.Item>
          <Form.Item hasFeedback>
            {getFieldDecorator('email', { initialValue: '', rules: [{ pattern: /[^@\s]+@[^@\s]+/ }] })(
              <Input type='text' placeholder={localization.email} />
            )}
          </Form.Item>
          <Form.Item hasFeedback>
            {getFieldDecorator('birthDate', { initialValue: '',
              rules: [ { required: true, message: localization.form.requiredField } ]
            })(
              <Input type='date' placeholder={localization.dayOfBirth} />
            )}
          </Form.Item>
          <Form.Item hasFeedback>
            {getFieldDecorator('password', {
              initialValue: '',
              rules: [ { required: true, message: localization.form.requiredField } ],
              validateTrigger: this.state.passwordTrigger
            })(
              <PasswordInput placeholder={localization.password} onBlur={this.handleChange} />
            )}
          </Form.Item>
          <div className='input_field'>
            <Button htmlType='submit' loading={loading} type='primary' block>
              {localization.auth.signUp}
            </Button>
          </div>
          <Link to='sign-in'>{localization.auth.alreadyRegistered}</Link>
        </form>
      </div>
    )
  }
}

RegistrationForm.propTypes = {
  form: PropTypes.object.isRequired,
  registerUser: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
}

export default compose(
  withRouter,
  Form.create()
)((props) => (
  <Mutation mutation={registerUserMutation}>
    {(registerUser, { data, loading }) => (
      <RegistrationForm registerUser={registerUser} data={data} loading={loading} {...props} />
    )}
  </Mutation>
))
