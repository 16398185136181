import { combineReducers, createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { createLogger } from 'redux-logger'
import Raven from 'raven-js'
import createRavenMiddleware from 'raven-for-redux'
import { auth, flash } from './reducers'

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['user', 'token'],
};
const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['flash'],
};

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  flash,
});
const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const middlewares = []

if (process.env.NODE_ENV !== 'production') {
  const logger = createLogger({})

  middlewares.push(logger)
}

if (process.env.NODE_ENV === 'production') {
  var sentryDSN = process.env.REACT_APP_SENTRY_DSN
  if (process.env.BUILD_MODE === 'iframe') {  // overwrite when iframe to sentry to different project
    sentryDSN = process.env.REACT_APP_SENTRY_DSN_IFRAME
  }
  Raven
    .config(sentryDSN)
    .install()
  middlewares.push(createRavenMiddleware(Raven, {}))
}

export default function configureStore (initialState) {
  let store = createStore(persistedReducer, initialState, applyMiddleware(...middlewares))
  let persistor = persistStore(store)
  return { store, persistor }
}
