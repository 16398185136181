import { connect } from 'react-redux'
import { Edit } from 'components/Profile'
import { bindActionCreators } from 'redux'
import { signOut } from 'actions/auth'
import { flashWarning } from 'actions/flash'

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ signOut, flashWarning }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Edit)
