import React from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";
import { compose, Query } from "react-apollo";
import Spin from "antd/lib/spin";
import { withRouter } from "react-router-dom";
import localization from "utils/localization";
import NETWORKS from "api/query/networks.gql";

class RegisterSelectNetwork extends React.Component {
  componentDidUpdate() {
    const { error, data } = this.props;
    const networks = !error && data && data.networks;
    if (networks && networks.length === 1) {
      this.props.setNetwork(networks[0].id);
    }
  }

  handleChange = (e) => {
    this.props.setNetwork(parseInt(e.currentTarget.value, 10));
  };

  render() {
    const {
      loading,
      data,
      error,
    } = this.props;
    
    if (error) {
      return null
    }

    if (loading) {
      return <Spin size="small" />;
    }


    return (
      <div className="RegisterSelectNetwork">
        <h1 className="RegisterSelectNetwork__Title">
          {localization.auth.networkSelection.title}
        </h1>
        <p>
          {localization.formatString(
            localization.auth.networkSelection.description,
            data.networks.length
          )}
        </p>

        <form
          name="networkForm"
          onSubmit={this.handleSubmit}
          className="RegisterSelectNetwork__Form"
        >
          <Row
            type="flex"
            justify="start"
            align="top"
            gutter={{ xs: 15, sm: 15, md: 30 }}
          >
            {data.networks.sort((a, b) => a.name.localeCompare(b.name)).map((network) => {
              return (
                <Col
                  key={network.id}
                  type="flex"
                  justify="center"
                  style={{ textAlign: "center" }}
                  span={12}
                >
                  <div className="NetworkCell">
                    <input
                      id={`network-${network.id}`}
                      value={network.id}
                      name="network"
                      type="radio"
                      onChange={this.handleChange}
                      className="NetworkCell__Input"
                    />
                    <div className="NetworkCell__Dot"></div>
                    <label
                      htmlFor={`network-${network.id}`}
                      className="NetworkCell__Label"
                    >
                      <div className="NetworkCell__Title">{network.name}</div>
                      <div className="NetworkCell__Subtitle">
                        {network.locationHint}
                      </div>
                    </label>
                  </div>
                </Col>
              );
            })}
          </Row>
        </form>
        {(() => {
          if (window.ECOV_NETWORK_SUGGESTION_URL) {
            return (
          <p className="RegisterSelectNetwork__Link">
            <a
              href={window.ECOV_NETWORK_SUGGESTION_URL}
              title={localization.auth.networkSelection.suggestionLink}
            >
              {localization.auth.networkSelection.suggestionLink}
            </a>
          </p>
            )
          }
        })()}
      </div>
    );
  }
}

RegisterSelectNetwork.propTypes = {
  data: PropTypes.object.isRequired,
  error: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  setNetwork: PropTypes.func.isRequired,
};

export default compose(withRouter)((props) => (
  <Query query={NETWORKS}>
    {({ loading, data, error }) => (
      <RegisterSelectNetwork data={data} loading={loading} error={error} {...props} />
    )}
  </Query>
));
