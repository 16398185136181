import { connect } from 'react-redux'
import { ConfirmationCode } from 'components/Auth'
import { signIn } from 'actions/auth'
import { bindActionCreators } from 'redux'

const mapStateToProps = state => ({
  auth: state.auth
})

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ signIn }, dispatch)
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationCode)
