import React from 'react'
import localization from 'utils/localization'

export default errorCode => {
  let message
  switch (errorCode) {
    case 'cannot_be_blank':
      message = localization.validations.cannotBeBlank
      break
    case 'already_exists':
      message = localization.validations.alreadyExists
      break
    case 'invalid_format':
      message = localization.validations.invalidFormat
      break
    default:
      message = errorCode
      break
  }

  return { message: <p style={{ marginBottom: 0 }}>{message}</p> }
}
