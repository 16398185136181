import React from 'react';
import signInUserMutation from '../../api/mutation/signInUser.gql';
import { Mutation, compose } from 'react-apollo';
import { withRouter, Link } from 'react-router-dom';
import { Button, Form, Alert, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import PasswordInput from '../../components/Common/PasswordInput';
import localization from '../../utils/localization';
import { PhoneInputFormItem } from './phone-input/PhoneInputFormItem';

const parseErrors = (error) => {
  if (Array.isArray(error)) return error;

  if (error.graphQLErrors) return Array.from(error.graphQLErrors);

  return [];
};

class SignIn extends React.Component {
  state = {
    errorMessages: [],
  };
  handleSubmit = (e) => {
    e.preventDefault();

    this.setState({ errorMessages: [] }, () => {
      this.props.form.validateFields((error, value) => {
        if (!error) {
          const phone = `+${value.phone}`;

          this.props
            .signInUser({
              variables: {
                input: { ...value, phone },
              },
            })
            .then((resp) => {
              if (resp.data.signInUser.errors.length) {
                return Promise.reject(resp.data.signInUser.errors);
              }
              this.props.signIn(resp.data.signInUser);
              this.props.history.push(
                'after-sign-in' + window.ECOV_IFRAME_PARAMS
              );
            })
            .catch((error) => {
              parseErrors(error).forEach((error) => {
                let errorMessages = this.state.errorMessages;
                errorMessages.push(error.message);
                this.setState({ errorMessages });
                if (error.code === 'phone_not_confirmed') {
                  this.props.history.push(`/confirmation-code/${value.phone}`);
                }
              });
            });
        }
      });
    });
  };

  render() {
    const { form, loading } = this.props;
    const { getFieldDecorator } = form;
    const { errorMessages } = this.state;

    return (
      <div className="form_wrapper">
        <Row
          type="flex"
          justify="center"
          className="display-none"
          style={{ marginBottom: '20px' }}
        >
          <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
            <h1 className="h1-style-inverse ">{localization.auth.signIn}</h1>
          </Col>
        </Row>
        <form onSubmit={this.handleSubmit}>
          {errorMessages.map((message, index) => (
            <Alert
              key={index}
              style={{ marginBottom: 20 }}
              message={message}
              type="error"
            />
          ))}
          <div>
            <Row type="flex" justify="center">
              <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
                <PhoneInputFormItem form={form} />
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
                <Form.Item>
                  {getFieldDecorator('password', {
                    initialValue: '',
                    rules: [
                      {
                        required: true,
                        message: localization.form.requiredField,
                      },
                    ],
                  })(<PasswordInput placeholder={localization.password} />)}
                </Form.Item>
              </Col>
            </Row>
            <Row type="flex" justify="center" style={{ marginBottom: '20px' }}>
              <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
                <Button
                  className="primary-button"
                  htmlType="submit"
                  loading={loading}
                  type="primary"
                >
                  {localization.auth.signIn}
                </Button>
              </Col>
            </Row>
          </div>
          <div>
            <Row type="flex" justify="center" style={{ marginBottom: '20px' }}>
              <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
                <Link
                  to="/sign-up"
                  query={window.ECOV_IFRAME_PARAM_ARRAY}
                  className="link-style-inverse"
                >
                  {localization.auth.notRegistered}
                </Link>
              </Col>
            </Row>
            <Row type="flex" justify="center">
              <Col type="flex" justify="center" style={{ textAlign: 'center' }}>
                <Link
                  to="/forgot-password"
                  query={window.ECOV_IFRAME_PARAM_ARRAY}
                  className="link-style-inverse"
                >
                  {localization.auth.forgotPassword}
                </Link>
              </Col>
            </Row>
          </div>
        </form>
      </div>
    );
  }
}

SignIn.propTypes = {
  form: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  signInUser: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  brand: PropTypes.object,
};

export default compose(
  withRouter,
  Form.create({ fieldNameProp: 'name' })
)((props) => (
  <Mutation mutation={signInUserMutation}>
    {(signInUser, { data, loading }) => (
      <SignIn
        signInUser={signInUser}
        data={data}
        loading={loading}
        {...props}
      />
    )}
  </Mutation>
));
