import React from 'react'
import { Row, Col, Form, Input, Button, Spin, Divider, notification } from 'antd'
import PropTypes from 'prop-types'
import localization from 'utils/localization'
import { Query, Mutation, compose } from 'react-apollo'
import { Link } from 'react-router-dom'
import ME from 'api/query/me.gql'
import UPDATE_USER from 'api/mutation/updateUser.gql'
import validationMessage from 'utils/validationMessage'

class Edit extends React.Component {
  handleSubmit = e => {
    e.preventDefault()

    this.props.form.validateFields((error, value) => {
      if (!error) {
        this.props.updateUser({
          variables: { input: value }
        })
          .then(resp => {
            if (resp.data.updateUser.errors.length) {
              return Promise.reject(resp.data.updateUser.errors)
            }
            if (!resp.data.updateUser.confirmed) {
              this.props.signOut()
              this.props.flashWarning({
                message: localization.auth.signedOutMessage.title,
                description: localization.auth.signInAgain
              })
              return
            }
            notification['success']({
              message: localization.profile.update,
              description: localization.profile.updateSuccess,
              placement: 'bottomRight'
            })
            this.props.history.push('/' + window.ECOV_IFRAME_PARAMS)
          })
          .catch(err => {
            const fields = err.reduce((fields, e) => {
              let errors = ((fields[e.attribute] && fields[e.attribute].errors) || [])
              errors.push(validationMessage(e.message))
              fields[e.attribute] = { value: value[e.attribute], errors }
              return fields
            }, {})

            this.props.form.setFields(fields)
          })
      }
    })
  }

  render () {
    const { form: { getFieldDecorator }, updating } = this.props

    return (
      <Query query={ME}>
        {({ loading, data: { me }, refetch }) => {
          if (loading) {
            return (
              <div className='form_wrapper' style={{ textAlign: 'center' }}>
                <Spin />
              </div>
            )
          }
          return (
            <Spin spinning={loading}>
              <Row>
                <Col>
                  <h2 className='profile-title-style'>{localization.profile.personnalInfos}</h2>
                </Col>
              </Row>
              <Row style={{ marginBottom: 15 }}>
                <Col>
                  <Divider />
                </Col>
              </Row>
              <form onSubmit={this.handleSubmit}>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item hasFeedback>
                      {getFieldDecorator('firstName', { initialValue: me.firstName, rules: [{ required: true }] })(
                        <Input className='ant-input-full-width' type='text' placeholder={localization.firstName} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item hasFeedback>
                      {getFieldDecorator('lastName', { initialValue: me.lastName, rules: [{ required: true }] })(
                        <Input className='ant-input-full-width' type='text' placeholder={localization.lastName} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item hasFeedback extra={localization.profile.willReconfirm}>
                  {getFieldDecorator('phone', { initialValue: me.phone, rules: [{ required: true }] })(
                    <Input className='ant-input-full-width' type='text' placeholder={localization.phone} />
                  )}
                </Form.Item>
                <Form.Item hasFeedback>
                  {getFieldDecorator('email', { initialValue: me.email, rules: [{ pattern: /[^@\s]+@[^@\s]+/ }] })(
                    <Input className='ant-input-full-width' type='text' placeholder={localization.email} />
                  )}
                </Form.Item>
                <Form.Item hasFeedback>
                  {getFieldDecorator('birthDate', { initialValue: me.birthDate, rules: [{ required: true }] })(
                    <Input className='ant-input-full-width' type='date' placeholder={localization.dayOfBirth} />
                  )}
                </Form.Item>
                <Form.Item hasFeedback>
                  {getFieldDecorator('address', { initialValue: me.address })(
                    <Input className='ant-input-full-width' type='text' placeholder={localization.address} />
                  )}
                </Form.Item>
                <Row gutter={8}>
                  <Col span={12}>
                    <Form.Item hasFeedback>
                      {getFieldDecorator('city', { initialValue: me.city })(
                        <Input className='ant-input-full-width' type='text' placeholder={localization.city} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={12}>
                    <Button htmlType='submit' type='primary' loading={updating} block>
                      {localization.submit}
                    </Button>
                  </Col>

                  <Col span={12}>
                    <Button type='default' block>
                      <Link to='/' >Annuler</Link>
                    </Button>
                  </Col>
                </Row>
              </form>
            </Spin>
          )
        }}
      </Query>
    )
  }
}

Edit.propTypes = {
  form: PropTypes.object.isRequired,
  updating: PropTypes.bool.isRequired,
  updateUser: PropTypes.func.isRequired,
  flashWarning: PropTypes.func.isRequired,
  signOut: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
}

export default compose(
  Form.create({ fieldNameProp: 'name' })
)(props => (
  <Mutation mutation={UPDATE_USER}>
    {(updateUser, { loading }) => (
      <Edit updateUser={updateUser} updating={loading} {...props} />
    )}
  </Mutation>
))
