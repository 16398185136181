import React from 'react';
import { Form, Button, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import ReactCodeInput from 'react-code-input';
import { compose, Mutation } from 'react-apollo';
import localization from 'utils/localization';
import REGISTER_USER_SMS from 'api/mutation/registerUserSms.gql';
import ButtonResendSms from './ButtonResendSms';

class RegistrationSmsStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValid: false,
      code: null,
      validateStatus: null,
      message: null,
    };
  }

  handleCodeValue = (code) => {
    let valid = code.length === 4;
    this.setState({ code: code.toUpperCase(), isValid: valid });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props
      .registerUserSmsStep({
        variables: {
          input: {
            phone: this.props.phone,
            phoneConfirmationCode: this.state.code,
          },
        },
      })
      .then((resp) => {
        if (resp.data.registerUserSmsStep.errors.length) {
          return Promise.reject(resp.data.registerUserSmsStep.errors);
        }
        this.setState({ error: false });
        this.props.codeValidated(this.state.code);
      })
      .catch((errors) => {
        console.log(errors);
        this.setState({
          validateStatus: 'error',
          message: errors[0].message,
        });
      });
  };
  render() {
    const { loading, phone } = this.props;

    return (
      <div className='form_wrapper'>
        <Row type='flex' justify='center' style={{ marginBottom: '20px' }}>
          <Col type='flex' justify='center' style={{ textAlign: 'center' }}>
            <h1 className='h1-style-inverse '>
              {localization.auth.SMSConfirmationCode}
            </h1>
            <p className='p-style-inverse'>+{phone}</p>
          </Col>
        </Row>
        <form onSubmit={this.handleSubmit}>
          <Row type='flex' justify='center'>
            <Col type='flex' justify='center' style={{ textAlign: 'center' }}>
              <Form.Item
                help={this.state.message}
                validateStatus={this.state.validateStatus}
                colon={false}
              >
                <ReactCodeInput
                  className='display-inline-block'
                  inputStyle={reactCodeInputStyle}
                  onChange={this.handleCodeValue}
                />
              </Form.Item>
            </Col>
          </Row>
          <div>
            <Row type='flex' justify='center' style={{ marginBottom: '20px' }}>
              <Col type='flex' justify='center' style={{ textAlign: 'center' }}>
                <Button
                  className='primary-button'
                  type='primary'
                  htmlType='submit'
                  disabled={!this.state.isValid}
                  loading={loading}
                >
                  {localization.submit}
                </Button>
              </Col>
            </Row>
          </div>
        </form>
        <div>
          <Row type='flex' justify='center'>
            <Col type='flex' justify='center' style={{ textAlign: 'center' }}>
              <ButtonResendSms phone={this.props.phone} />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

RegistrationSmsStep.propTypes = {
  registerUserSmsStep: PropTypes.func.isRequired,
  codeValidated: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  phone: PropTypes.string.isRequired,
};

const reactCodeInputStyle = {
  fontFamily: 'monospace',
  MozAppearance: 'textfield',
  borderRadius: '6px',
  border: '1px solid',
  boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
  margin: '4px',
  paddingLeft: '8px',
  width: '36px',
  height: '42px',
  fontSize: '14px',
  boxSizing: 'border-box',
  textTransform: 'uppercase',
};

export default compose(Form.create())((props) => (
  <Mutation mutation={REGISTER_USER_SMS}>
    {(registerUserSmsStep, { loading }) => (
      <RegistrationSmsStep
        registerUserSmsStep={registerUserSmsStep}
        loading={loading}
        {...props}
      />
    )}
  </Mutation>
));
