import React from 'react'
import { notification, Button } from 'antd'
import PropTypes from 'prop-types'
import { Mutation } from 'react-apollo'
import localization from 'utils/localization'
import REGISTER_USER_PHONE from 'api/mutation/registerUserPhone.gql'

class ButtonResendSms extends React.Component {
  resendCode = () => {
    this.props.registerUserPhoneStep({
      variables: { input: { phone: this.props.phone } }
    }).then(resp => {
      if (resp.data.registerUserPhoneStep.errors.length) {
        return Promise.reject(resp.data.registerUserPhoneStep.errors)
      }
      this.setState({
        error: false
      })
      notification['success']({
        message: localization.auth.resendConfirmationCode.success.title,
        description: localization.auth.resendConfirmationCode.success.description,
        duration: 5,
        placement: 'bottomRight'
      })
    })
      .catch(() => {
        notification['error']({
          message: localization.auth.resendConfirmationCode.failure.title,
          description: localization.auth.resendConfirmationCode.failure.description,
          duration: 5,
          placement: 'bottomRight'
        })
      })
  }
  render () {
    const { loading } = this.props
    return (
      <Button type='primary' ghost htmlType='submit' loading={loading} block onClick={this.resendCode}>
        {localization.auth.resendCode}
      </Button>
    )
  }
}

ButtonResendSms.propTypes = {
  phone: PropTypes.string.isRequired,
  registerUserPhoneStep: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired
}

export default props => (
  <Mutation mutation={REGISTER_USER_PHONE}>
    {(registerUserPhoneStep, { loading }) => (
      <ButtonResendSms registerUserPhoneStep={registerUserPhoneStep} loading={loading} {...props} />
    )}
  </Mutation>
)
