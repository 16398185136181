import LocalizedStrings from "react-localization";

export default new LocalizedStrings({
  fr: {
    phone: "Téléphone",
    dayOfBirth: "Date de naissance",
    password: "Mot de passe",
    passwordPlaceholder: "Mot de passe (6 car.)",
    firstName: "Prénom",
    lastName: "Nom",
    email: "Email",
    dayOfBitrh: "Date de naissance",
    address: "Adresse",
    city: "Ville",
    postalCode: "Code postal",
    shareCode: "Code Conducteur",
    submit: "Valider",
    edit: "Modifier",
    cancel: "Annuler",
    back: "Retour",
    form: {
      requiredField: "Ce champ est obligatoire",
      codeLength: "Le code semble invalide",
      invalidEmail: "L'email est invalide",
      passwordField: "6 caractères minimum",
    },
    common: {
      error: "Erreur",
      yes: "Oui",
      no: "Non",
    },
    auth: {
      failure: "Téléphone ou mot de passe invalide",
      notRegistered: "Je n'ai pas encore de compte",
      notConfirmed: "Votre numéro de téléphone n'est pas encore confirmé.",
      signIn: "Se connecter",
      signUp: "Créer votre compte",
      signUpCreate: "Je crée mon compte",
      alreadyRegistered: "J'ai déjà un compte",
      forgotPassword: "J'ai oublié mon mot de passe",
      resendCode: "Renvoyer le code",
      signedInMessage: {
        title: "Connexion",
        description: "Bienvenue, vous avez été authentifié avec succès.",
      },
      signedOutMessage: {
        title: "Déconnexion",
        description: "Vous avez bien été déconnecté(e)",
      },
      forgotPasswordScreen: {
        title: "Mot de passe oublié",
        description:
          "Entrez votre numéro de téléphone, nous vous enverrons un lien par SMS pour créer un nouveau mot de passe",
      },
      SMSConfirmationCode:
        "Nous vous avons envoyé un code de confirmation par SMS",
      sessionExpired: {
        title: "Session expirée",
        description: "Vous devez vous connecter pour continuer.",
      },
      confirmationCode: "Code de confirmation",
      resendConfirmationCode: {
        success: {
          title: "SMS de confirmation",
          description:
            "Nous vous avons à nouveau envoyé un code par SMS. Si vous ne le recevez pas, vous pouvez nous appeler au 01 79 73 89 73",
        },
        failure: {
          title: "SMS de confirmation",
          description:
            "Une erreur s'est produite pendant que nous vous envoyions votre SMS de confirmation. Veuillez réessayer ou contacter l'assistance.",
        },
        request: "Vous n'avez pas reçu de code de confirmation ?",
      },
      passwordReset: {
        requestError:
          "Une erreur s'est produite. Avez-vous bien entré votre numéro de téléphone ?",
        verificationInputLabel:
          "Veuillez saisir le code envoyé par SMS pour réinitialiser votre mot de passe",
        verificationCode: "Code de vérification",
        verificationError:
          "Une erreur s'est produite. Avez vous saisi le bon code de vérification ?",
      },
      signInAgain: "Vous devez vous reconnecter pour continuer",
      networkSelection: {
        title: "Je m'inscris",
        description:
          "C'est parti ! Nous vous proposons {0} lignes : choisissez la votre.",
        suggestionLink: "Pas de ligne près de chez vous ?",
      },
    },
    validations: {
      cannotBeBlank: "Ce champ ne peut être vide",
      alreadyExists: "Est déjà utilisé",
      invalidFormat: "Format incorrect",
    },
    profile: {
      title: "Mon profil",
      personnalInfos: "Informations personnelles",
      identifier: "Identifiants",
      bankAccount: "Compte bancaire",
      completedAt: "Profil complété à",
      editProfile: "Je mets à jour mon profil",
      commuteList: "Mes trajets",
      addIban: "Ajouter un RIB",
      willReconfirm:
        "Vous devrez reconfirmer votre numéro de téléphone si vous le modifiez",
      update: "Mise à jour du profil",
      updateSuccess: "Votre profil a été mis à jour avec succès",
      myBankAccounts: "Mes comptes bancaires",
      myGains: "Ma cagnotte",
      addBankAccountError:
        "Une erreur s'est produite lors de l'ajout du compte bancaire",
    },
    moneyPot: {
      title: "Ma cagnotte",
      available: "Disponible",
      transfertMoney: "Transférer de l'argent",
      addAnAccount: "Veuillez d'abord ajouter un compte bancaire",
      wireMoneyError: "Une erreur s'est produite lors du transfert d'argent",
      wireMoneySuccess:
        "Le montant de votre cagnotte a bien été transféré sur votre compte bancaire.",
      creditMoneySuccess: "La transaction a bien été effectuée.",
      creditMoneyFailure: "Il s'est produit une erreur pendant la transaction.",
      payInForm: {
        cardNumberLabel: "Saisissez votre numéro de carte bancaire :",
        cardNumber: "Numéro de carte",
        amount: "Saisissez le montant à créditer :",
      },
    },
  },
});
