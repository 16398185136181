import { Icon } from 'antd'
import styled from 'styled-components'

export default styled(Icon)`
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s;
  font-size: 14px;

  &:hover {
    color: #40a9ff
  }
`
