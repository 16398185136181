import React from 'react'
import Profile, { MoneyPot, CreditMoney } from 'components/Profile'
import ProfileEdit from 'containers/ProfileEdit'
import BankDetails from 'containers/BankDetails'
import WireMoney from 'containers/WireMoney'
import { MemoryRouter as Router, Route, Switch, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import localization from 'utils/localization'
import { Row, Col, Layout } from 'antd'
import PropTypes from 'prop-types'
import SignOut from 'pages/SignOut'

const { Sider, Content } = Layout

class ProfileMenu extends React.Component {
  render () {
    return (
      <React.Fragment>
        <Row type='flex' style={{ marginLeft: '35px', marginTop: '50px' }}>
          <Col>
            <Link className='menu-link-style-inverse' to='/'>{localization.profile.title}</Link>
          </Col>
        </Row>
        <Row type='flex' style={{ marginLeft: '35px', marginTop: '50px' }} />
        <Row type='flex' style={{ marginLeft: '35px', marginTop: '10px' }}>
          <Col>
            <Link className='menu-link-style-inverse' to='/money-pot'>{localization.profile.myGains}</Link>
          </Col>
        </Row>
        <Row type='flex' style={{ marginLeft: '35px', marginTop: '50px' }} />
        {window.ECOV_IFRAME &&
        <Row type='flex' style={{ marginLeft: '35px', marginTop: '10px' }}>
          <Col>
            <Link className='menu-link-style-inverse' to='/sign-out'>{localization.auth.signedOutMessage.title}</Link>
          </Col>
        </Row>
        }
      </React.Fragment>
    )
  }
}

class ProfilePage extends React.Component {
  renderProfileRoutes () {
    return (
      <Layout>
        <Sider
          width={200}
          className='left-search-menu'
          breakpoint='lg'
          collapsedWidth='0'>
          <ProfileMenu />
        </Sider>
        <Content style={{ margin: 30 }}>
          <Switch>
            <Route component={Profile} path='/' exact />
            <Route component={ProfileEdit} path='/profile/edit' exact />
            <Route component={BankDetails} path='/bank-details' exact />
            <Route component={MoneyPot} path='/money-pot' exact />
            <Route component={CreditMoney} path='/credit-money' exact />
            <Route component={WireMoney} path='/wire-money' exact />
            <Route component={SignOut} path='/sign-out' exact />
          </Switch>
        </Content>
      </Layout>
    )
  }

  renderNotAuthenticatedRoute () {
    if (window.ECOV_IFRAME) {
      window.location = 'sign-in' + window.ECOV_IFRAME_PARAMS
    }
    else {
      window.location = process.env.REACT_APP_SIGN_IN_PATH
    }
    return null
  }

  render () {
    return (
      <div className='profile'>
        <Router>
          {
            this.props.auth.user ? this.renderProfileRoutes() : this.renderNotAuthenticatedRoute()
          }
        </Router>
      </div>
    )
  }
}

ProfilePage.propTypes = {
  auth: PropTypes.object
}

export default connect(
  state => ({ auth: state.auth })
)(ProfilePage)
