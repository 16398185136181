export const actionTypes = {
  SET: 'flash/SET',
  CLEAR: 'FLASH/CLEAR'
}

const messageTypes = {
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  error: 'error'
}

export const flashSuccess = (notification) => {
  return setFlashMessage(notification, messageTypes.SUCCESS)
}

export const flashInfo = (notification) => {
  return setFlashMessage(notification, messageTypes.INFO)
}

export const flashWarning = (notification) => {
  return setFlashMessage(notification, messageTypes.WARNING)
}

export const flashError = (notification) => {
  return setFlashMessage(notification, messageTypes.ERROR)
}

export const clearFlashMessage = () => ({
  type: actionTypes.CLEAR
})

const setFlashMessage = ({ message, description }, type) => ({
  type: actionTypes.SET,
  payload: { message, description, type }
})
