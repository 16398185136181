import { Component } from 'react'
import { connect } from 'react-redux'
import notification from 'antd/lib/notification'
import { bindActionCreators } from 'redux'
import { clearFlashMessage } from 'actions/flash'
import PropTypes from 'prop-types'
import 'antd/lib/icon/style/css'

//  Oyez, Oyez
//
//   /\`--.
//  |o-|   )>=====o
//   \/.--'
//
// This component handle stuffs like setting body class depending
// on whether the user is authenticated or not
// and displaying flash messages
class Announcer extends Component {
  state = {}

  static getDerivedStateFromProps (props) {
    const body = document.body

    if (props.auth.user && !body.classList.contains('covoitici-user-logged-in')) {
      body.classList.add('covoitici-user-logged-in')
    } else if (!props.auth.user && body.classList.contains('covoitici-user-logged-in')) {
      body.classList.remove('covoitici-user-logged-in')
    }
    return null
  }

  render () {
    return null
  }

  componentDidMount () {
    const { message, description, type } = this.props.flash
    if (this.props.flash.message) {
      notification[type]({
        message, description, placement: 'bottomRight'
      })
      this.props.clearFlashMessage()
    }
  }
}

Announcer.propTypes = {
  flash: PropTypes.object.isRequired,
  clearFlashMessage: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ clearFlashMessage }, dispatch)
}

export default connect(
  (state) => ({ flash: state.flash, auth: state.auth }),
  mapDispatchToProps
)(Announcer)
