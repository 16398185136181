import React, { Component } from 'react';
import { compose, Mutation } from 'react-apollo';
import { Form, Button } from 'antd';
import RECOVER_PASSWORD from 'api/mutation/recoverPassword.gql';
import PropTypes from 'prop-types';
import localization from 'utils/localization';
import PasswordInput from 'components/Common/PasswordInput';
import validationMessage from 'utils/validationMessage';

class RecoverPassword extends Component {
  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((error, value) => {
      if (!error) {
        const { phone, verificationCode } = this.props.match.params;
        this.props
          .recoverPassword({
            variables: {
              input: { ...value, phone: `+${phone}`, verificationCode },
            },
          })
          .then((resp) => {
            if (resp.data.recoverPassword.errors.length) {
              return Promise.reject(resp.data.recoverPassword.errors);
            }
            this.props.signIn({
              ...resp.data.recoverPassword.user,
              token: resp.data.recoverPassword.token,
            });
            this.props.history.push(
              '/after-sign-in' + window.ECOV_IFRAME_PARAMS
            );
          })
          .catch((err) => {
            const fields = err.reduce((fields, e) => {
              let errors =
                (fields[e.attribute] && fields[e.attribute].errors) || [];
              errors.push(validationMessage(e.message));
              fields[e.attribute] = { value: value[e.attribute], errors };
              return fields;
            }, {});

            this.props.form.setFields(fields);
          });
      }
    });
  };
  render() {
    const {
      form: { getFieldDecorator },
      loading,
    } = this.props;
    return (
      <div className="form_wrapper">
        <form onSubmit={this.handleSubmit}>
          <Form.Item>
            {getFieldDecorator('password', {
              initialValue: '',
              rules: [
                { required: true, message: localization.form.requiredField },
              ],
            })(<PasswordInput placeholder={localization.password} />)}
          </Form.Item>
          <div className="input_field">
            <Button type="primary" htmlType="submit" loading={loading} block>
              {localization.submit}
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

RecoverPassword.propTypes = {
  recoverPassword: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  signIn: PropTypes.func.isRequired,
};

export default compose(Form.create())((props) => (
  <Mutation mutation={RECOVER_PASSWORD}>
    {(recoverPassword, { loading }) => (
      <RecoverPassword
        recoverPassword={recoverPassword}
        loading={loading}
        {...props}
      />
    )}
  </Mutation>
));
