import { actionTypes } from 'actions/auth'

const initialState = {
  network: null,
  user: null,
  token: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_NETWORK:
      return { 
        ...state, 
        network: action.payload 
      };
    case actionTypes.SIGN_IN:
      return { ...action.payload };
    case actionTypes.SIGN_OUT:
      return { ...initialState };

    default:
      return state;
  }
}
