import React from 'react'
import Auth from 'pages/Auth'
import SignOut from 'pages/SignOut'
import Profile from 'pages/Profile'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Layout } from 'antd'
import logo from './images/logo.png'

const LandingPage = () => (
  <div className='form_wrapper'>
    <img src={logo} alt='logo' />
  </div>
)

const NoBrandPage = () => (
  <div className='form_wrapper'>
    <h1>Missing brand information !</h1>
  </div>
)

class IframeRouter extends React.Component {
  
  render () {
    if (!window.BRAND_TOKEN) {
      return <NoBrandPage />
    }
    return (
      <Router>
        <Layout className='layout'>
          <Layout.Content style={{ background: '#fff' }}>
            <Switch>
              <Route path='/' component={LandingPage} exact />
              <Route path='/sign-up' component={Auth} exact />
              <Route path='/sign-in' render={() => <Auth isSigningIn />} exact />
              <Route path='/sign-out' component={SignOut} exact />
              <Route path='/profile' component={Profile} exact />
              <Route path='/error-nobrand' component={NoBrandPage} exact />
            </Switch>
          </Layout.Content>
        </Layout>
      </Router>
    )
  }
}

export default IframeRouter